<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-boody" v-if="role" style="padding: 20px">
          <div class="descriptions">
            <div class="descriptions-header" style="display: flex;justify-content: space-between;align-items: center;">
              <div class="descriptions-title" style="padding-left: 20px;">角色信息</div>
              <div style="color: #409EFF" @click="handleEdit">编辑</div>
            </div>
            <div class="descriptions-view" style="padding-left: 20px;">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">角色名称</span>
                      <span class="descriptions-item-content">{{ role.name }}</span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="3">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">角色描述</span>
                      <span class="descriptions-item-content">
                      {{ role.describe }}
                      </span>
                    </div>
                  </td>
                </tr>
                <!--      <tr class="descriptions-row">
                        <td class="descriptions-item" colspan="1">
                          <div class="descriptions-item-container">
                            <span class="descriptions-item-label">状态</span>
                            <span class="descriptions-item-content">
                              {{ role.close ? '已禁用' : '正常' }}
                            </span>
                          </div>
                        </td>
                       &lt;!&ndash; <td class="descriptions-item" colspan="1">
                          <div class="descriptions-item-container">
                            <span class="descriptions-item-label">开发商默认角色</span>
                            <span class="descriptions-item-content">
                              {{ role.isDevelopersDefault ? '是' : '否' }}
                            </span>
                          </div>
                        </td>&ndash;&gt;
                        <td class="descriptions-item" colspan="1"></td>
                        <td class="descriptions-item" colspan="1"></td>
                      </tr>-->
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="4">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">功能权限</span>
                      <span class="descriptions-item-content">{{ role_menus.join('、') }}</span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions">
            <div class="descriptions-header" style="display: unset;">
              <div class="descriptions-title" style="padding-left: 20px;">关联用户</div>
              <div style="margin-top:10px;padding-left: 20px;">
                <el-button type="primary" size="mini" @click="handleSetUser">设置用户</el-button>
              </div>
            </div>
            <div class="descriptions-view">
              <page-table ref="table" show-paginate :get-data="roleUserPage" :get-data-params="[id]" :fields="fields"
                          :show-search="false" :auto-load="false" :show-header="false" :no-gutter="true"
                          :item-actions="actions" @onActionClick="handleItemActionClick">
              </page-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
            title="设置用户"
            :visible.sync="user_dialog_visible"
            append-to-body
            :close-on-click-modal="false" width="35%" custom-class="min-w-750">
      <el-form :model="userForm" :rules="userRules" label-width="120px" ref="userForm"
               v-loading="user_form_loading">
        <el-form-item label="关联用户" prop="userIdsArr">
          <el-checkbox-group v-model="userForm.userIdsArr" @change="userIdsChange">
            <el-checkbox v-for="user in user_options" :label="user.id" :key="user.id">{{ user.name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="user_dialog_visible = false">取 消</el-button>
          <el-button @click="submit('userForm')" :disabled="userForm.userIdsArr.length < 1" type="primary">确 定
          </el-button>
        </div>
      </el-form>
    </el-dialog>
    <!--编辑弹框-->
    <el-dialog title="编辑角色" :visible.sync="edit_dialog_visible" append-to-body
               :close-on-click-modal="false" width="35%" custom-class="min-w-750">
      <el-form :model="editPageForm" :rules="editRules" label-width="120px" ref="editPageForm"
               v-loading="edit_form_loading">
        <el-form-item label="名称" prop="name">
          <el-input placeholder="名称" v-model="editPageForm.name" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="describe">
          <el-input placeholder="描述(最多100字符)" v-model="editPageForm.describe" maxlength="100" type="textarea"
                    rows="4"></el-input>
        </el-form-item>
        <el-form-item label="菜单" prop="menuIdsArr">
          <el-tree ref="editTree" node-key="id" :data="menus" :props="props" show-checkbox
          :default-expanded-keys="['pc', 'wx']"></el-tree>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="edit_dialog_visible = false">取 消</el-button>
          <el-button @click="editSubmit('editPageForm')" type="primary">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import {
  roleInfo,
  roleMenus,
  roleUserPage,
  roleNotSyncUserAll,
  roleClearUser,
  roleSyncUsers,
  roleUpdate,
  menuAll
} from "@/api/common"

export default {
  name: "RoleInfo",
  data() {
    return {
      id: null,
      role: null,
      role_menus: [],
      roleUserPage: roleUserPage,
      fields: [
        {label: 'UserID', key: 'id', width: 180,},
        {label: '姓名', key: 'name', width: 150,},
        {
          label: '性别', key: 'gender', width: 80,
          render({gender}, h) {
            return h('div', gender == 0 ? '男' : (gender == 1 ? '女' : ''))
          }
        },
        {label: '手机号', key: 'phone', width: 160},
        {
          label: '用户类型', key: 'userType', width: 100,
          render({userType}, h) {
            let types = {
              admin: '集团用户',
              enterprise: '企业用户',
              distributor: '经销商',
            }
            if (types[userType] !== undefined) {
              return h('div', types[userType])
            }
            return h('div')
          }
        },
        {
          label: '归属组织',
          render: ({developers, distributor, userType}, h) => {
            if (!userType) {
              return h("span", '')
            } else if (userType == 'admin') {
              return h("span", "新能源")
            } else if (userType == 'enterprise') {
              return h("span", developers.name)
            } else if (userType == 'distributor') {
              return h("span", distributor.name)
            }
          }
        },
        {label: '创建时间', key: 'time', width: 180, align: 'center'},
        {label: '修改时间', key: 'uTime', width: 180, align: 'center'},
      ],
      actions: [
        {action: 'quit', label: '取消关联', type: 'primary'},
      ],
      //
      user_form_loading: false,
      userForm: {
        userIdsArr: [],
        userIds: '',
      },
      userRules: {},
      user_dialog_visible: false,
      user_options: [],
      //////////
      edit_dialog_visible: false,
      edit_form_loading: false,
      editPageForm: {
        name: '',
        describe: '',
        menuIds: '',
        menuIdsArr: [],
      },
      editRules: {
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'},
        ],
      },
      menus: [],
      props: {
        label: "name",
        children: "child",
      },
    }
  },
  components: {
    PageHeader,
    PageTable,
  },
  created() {
    this.id = this.$route.params.id
  },
  mounted() {
    this.getMenuAll()
    this.getRoleInfo()
  },
  methods: {
    getMenuAll() {
      let that = this
      menuAll().then(res => {
        let temp = []
        if(res.wx !== undefined && res.wx.length > 0){
          temp.push({
            id: 'wx',
            name: '小程序端',
            child: res.wx,
            disabled: true
          })
        }
        if(res.pc !== undefined && res.pc.length > 0){
          temp.push({
            id: 'pc',
            name: 'PC后台管理端',
            child: res.pc,
            disabled: true
          })
        }
        that.menus = temp
      })
    },
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    getRoleInfo() {
      let that = this
      roleInfo(that.id).then(res => {
        that.role = res
        that.getRoleMenus()
        setTimeout(() => {
          that.$refs.table.loadData()
        }, 200)
      })
    },
    getRoleMenus() {
      let that = this
      roleMenus(that.id).then(res => {
        let role_menus = []
        for (let i in res) {
          role_menus.push(res[i].name)
        }
        this.role_menus = role_menus
      })
    },
    quitAction(item) {
      let that = this
      that.$confirm('此操作将取消关联用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        roleClearUser(that.id, item.id).then(() => {
          that.$message.success('取消成功');
          that.$refs.table.loadData()
        }).catch(() => {
          that.$message.error('操作失败')
        })
      })
    },
    handleSetUser() {
      let that = this
      that.userForm.userIds = ''
      that.userForm.userIdsArr = []
      that.user_dialog_visible = true
      that.user_form_loading = true
      roleNotSyncUserAll(that.id).then(res => {
        that.user_options = res
        that.user_form_loading = false
      }).catch(() => {
        that.user_form_loading = false
        that.user_dialog_visible = false
      })
    },
    userIdsChange(val) {
      this.userForm.userIds = val.join(',')
    },
    submit(ref) {
      let that = this
      if (that.userForm.userIdsArr.length < 1) return
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        that.user_form_loading = true
        roleSyncUsers(that.id, that.userForm).then(() => {
          that.user_form_loading = false
          that.$message.success('提交成功')
          that.user_dialog_visible = false
          that.$refs.table.loadData()
        }).catch(() => {
          that.user_form_loading = false
        })
      })
    },
    handleEdit() {
      let that = this;
      let menuIdsArr = that.role.menuIds.split(',')
      that.editPageForm = {
        id: that.role.id,
        name: that.role.name,
        describe: that.role.describe,
        menuIds: that.role.menuIds,
        menuIdsArr: menuIdsArr,
        version: that.role.version,
      }
      setTimeout(() => {
        that.$refs['editTree'].setCheckedKeys([]);
        menuIdsArr.forEach((i,) => {
          const node = that.$refs['editTree'].getNode(i);
          if (node != null && node.isLeaf) {
            that.$refs['editTree'].setChecked(node, true)
          }
        })
      }, 2000)
      that.edit_dialog_visible = true
    },
    editSubmit(formName) {
      let that = this
      let checkedKeys = that.$refs['editTree'].getCheckedKeys();
      let hafCheckedKeys = that.$refs['editTree'].getHalfCheckedKeys()
      hafCheckedKeys = hafCheckedKeys.filter(item => {
        return item != undefined
      })
      let ids = checkedKeys.concat(hafCheckedKeys)
      ids = ids.filter(item => {
        return item != 'wx' && item != 'pc'
      })
      that.editPageForm.menuIds = ids.join(",");
      that.$refs[formName].validate((valid) => {
        if (!valid) {
          return false
        }
        that.eidt_form_loading = true
        roleUpdate(that.editPageForm).then(() => {
          that.eidt_form_loading = false
          that.$message.success('提交成功')
          that.edit_dialog_visible = false
          that.getRoleInfo()
        }).catch(() => {
          that.eidt_form_loading = false
        })
      })
    },
  },
}
</script>

<style scoped>

</style>